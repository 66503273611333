import React, { FC, PropsWithChildren } from 'react';
import { navigate } from 'gatsby';
import { Box, BoxProps, Container, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { BackArrow } from '../SvgIcon';

type DataProps<P> = P & Record<string, unknown>;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(8),

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(9),
    },

    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(12),
    },
  },

  rootIconButton: {
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(5),
    },

    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(6),
    },
  },
}));

const MarginTopContainer: FC<PropsWithChildren<DataProps<BoxProps>>> = ({
  children,
  ...restProps
}) => {
  const classes = useStyles();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Box className={classes.root} {...restProps}>
      <Container>
        <IconButton
          className={classes.rootIconButton}
          disableRipple
          disableTouchRipple
          aria-label="back"
          onClick={handleBackClick}
        >
          <BackArrow fontSize="large" />
        </IconButton>
      </Container>

      {children}
    </Box>
  );
};

export default MarginTopContainer;
