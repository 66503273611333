import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import Layout from '../components/Layout';
import MarginTopContainer from '../components/MarginTopContainer';
import SEO from '../components/SEO';
import { InfoSection } from '../sections/common';
import { ContactSection } from '../sections/ContactPage';

type DataProps = Record<string, unknown>;

const ContactPage: FC<PageProps<DataProps>> = () => {
  return (
    <Layout>
      <SEO title="Kontakt - Půjčovna kol BeRider" />

      <MarginTopContainer>
        <ContactSection />
        <InfoSection showContactButton={false} />
      </MarginTopContainer>
    </Layout>
  );
};

export default ContactPage;
