import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import Img from 'gatsby-image';

type DataProps = Record<string, unknown>;

const RESPONSIVE_BOX_BOTTOM_MARGIN_1 = [2, 3];
const RESPONSIVE_BOX_BOTTOM_MARGIN_2 = [4, 6, 6, 8];
const RESPONSIVE_BOX_BOTTOM_MARGIN_3 = [2, 4, 6];
const RESPONSIVE_BOX_BOTTOM_MARGIN_4 = [2, 4];

const RESPONSIVE_CONTAINER_JUSTIFY = ['center', 'center', 'space-between'];

const ContactpageContactSection: FC<DataProps> = () => {
  const data = useStaticQuery(graphql`
    query ContactImageQuery {
      contactImage: file(relativePath: { eq: "berider-van.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <Container>
      <Box justifyContent={RESPONSIVE_CONTAINER_JUSTIFY} mb={5} clone>
        <Grid container>
          <Grid item xs={12} sm={10} md={6} lg={5}>
            <Box mb={RESPONSIVE_BOX_BOTTOM_MARGIN_1}>
              <Typography component="h1" variant="h2">
                Kontakt
              </Typography>
            </Box>

            <Box mb={RESPONSIVE_BOX_BOTTOM_MARGIN_2}>
              <Typography component="div" variant="h5">
                Máte otázku nebo si nevíte rady?
                <br />
                Rádi Vám pomůžeme.
              </Typography>
            </Box>

            <Box mb={RESPONSIVE_BOX_BOTTOM_MARGIN_4}>
              <Typography component="div" variant="h6">
                BeRider
              </Typography>

              <Typography component="div" variant="body1" gutterBottom>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    Všední dny
                  </Grid>
                  <Grid item xs={6}>
                    7:00 - 21:00
                  </Grid>
                  <Grid item xs={6}>
                    Víkendy a svátky
                  </Grid>
                  <Grid item xs={6}>
                    9:00 - 21:00
                  </Grid>
                </Grid>
              </Typography>

              <Typography component="div" variant="body1" gutterBottom>
                * Možnost doručení kol i v jiné časy na základě domluvy.
              </Typography>

              <Typography
                component="a"
                color="primary"
                variant="h5"
                gutterBottom
                href="tel:+420704862068"
              >
                +420 704 862 068
              </Typography>
            </Box>

            <Box mb={RESPONSIVE_BOX_BOTTOM_MARGIN_3}>
              <Typography component="div" variant="h6">
                Napište nám
              </Typography>

              <Typography
                component="a"
                color="primary"
                variant="h5"
                href="mailto: bikes@be-rider.com"
              >
                bikes@be-rider.com
              </Typography>
            </Box>

            <Box mb={RESPONSIVE_BOX_BOTTOM_MARGIN_4}>
              <Typography component="address" variant="subtitle1" gutterBottom>
                BeRider s.r.o.
                <br />
                Jankovcova 1587/8d, Holešovice
                <br />
                170 00 Prague 7<br />
                Czech Republic
              </Typography>

              <Typography component="div" variant="subtitle1" gutterBottom>
                IČO: 08073198
                <br />
                DIČ: CZ08073198
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={10} md={6} lg={6}>
            <Box mb={1}>
              <Img
                fluid={data.contactImage.childImageSharp.fluid}
                alt="Berider Office"
                imgStyle={{ objectFit: 'contain' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ContactpageContactSection;
